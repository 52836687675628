import 'styled-components';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import Welcome from '../../pages/Welcome';
import Tutorial from '../../pages/Tutorial';
import Questions from '../../pages/Questions';
import Answers from '../../pages/Answers';
import AppGlobalStyle from '@keyslabor/shared/containers/AppGlobalStyle';
import AppGlobalStyleOverride from './AppGlobalStyle';
import theme from '@keyslabor/shared/theme';
import Finish from '@/pages/Finish';
import { useEffect } from 'react';
import { routes } from '@/constants';
import { useQuestionsAnswers } from '@/hooks/useQuestionsAnswers';
import ContainerFullPage from '@/components/ContainerFullPage';
import PreloaderFullPage from '@/components/PreloaderFullPage';
import InterviewPreview from '@/pages/InterviewPreview';
import OfferConfirmation from '@/pages/OfferConfirmation';
import './App.css';
import {DesktopRecordBlock} from "@/pages/Answers/LandscapeBlock.tsx";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const outOfInterviewScopeRoutes = [routes.interviewPreview, routes.offerConfirmation];

function App() {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(search);
  const debug = !!params?.get?.('debug');
  const turnOffHandleRouting = !!params?.get?.('turn_off_handle_routing');

  const { invitationKey, remoteStatus, isLoading, isError, error } =
    useQuestionsAnswers();

  // [DEBUG] bypass token
  // const isNoToken = false;
  const isNoToken = invitationKey == null;

  // TODO: remove route handling at all
  useEffect(() => {
    if (turnOffHandleRouting) {
      return;
    }
    switch (remoteStatus?.status) {
      case 'start':
        //TODO: inspect this condition
        /*if (![routes.root, routes.welcome, routes.tutorial].includes(pathname)) {
          navigate(routes.tutorial);
        }*/
        break;
      // case 'in_progress':
      //   if ([routes.questions, routes.answers].includes(pathname)) {
      //     navigate(routes.questions);
      //   }
      //   break;
      case 'finished':
        if (![routes.interviewPreview, routes.offerConfirmation].includes(pathname)) {
          navigate(routes.finish);
        }
        break;
    }
  }, [remoteStatus?.status, turnOffHandleRouting, navigate, pathname]);

  // @ts-expect-error - GlobalStyle type is not compatible with JSX but works
  const appGlobalStyle = <AppGlobalStyle theme={theme} />;
  // @ts-expect-error - GlobalStyle type is not compatible with JSX but works
  const appGlobalStyleOverride = <AppGlobalStyleOverride theme={theme} />;

  const stylesRender = (
    <>
      {appGlobalStyle}
      {appGlobalStyleOverride}
    </>
  );

  // [DEBUG] Error boundary
  // throw new Error(
  //   'Uncaught error: Error: Something went wrong. Please refresh the page and try again'
  // );
  if (isLoading) {
    return (
      <>
        {stylesRender}
        <PreloaderFullPage />
      </>
    );
  }

  if (!isLoading && (isNoToken || isError || !remoteStatus?.status)) {
    return (
      <>
        {stylesRender}
        <ContainerFullPage>
          Please provide a valid invitation key
          {debug && (
            <pre>
              <code>{isError ? JSON.stringify(error) : null}</code>
            </pre>
          )}
        </ContainerFullPage>
      </>
    );
  }

  const isInterviewScopePage = !outOfInterviewScopeRoutes.includes(pathname);

  return (
    <>
      {stylesRender}
      <ScrollToTop />
      {isInterviewScopePage ? <DesktopRecordBlock /> : null}
      <Routes>
        <Route path={routes.root} element={<Welcome />} />
        <Route path={routes.tutorial} element={<Tutorial />} />
        <Route path={routes.questions} element={<Questions />} />
        <Route path={routes.answers} element={<Answers />} />
        <Route path={routes.finish} element={<Finish />} />
        <Route path={routes.interviewPreview} element={<InterviewPreview />} />
        <Route path={routes.offerConfirmation} element={<OfferConfirmation />} />
        <Route path="*" element={<Navigate to={routes.root} replace />} />
      </Routes>
    </>
  );
}

export default App;
