import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { ButtonProps } from './Button.types';

import {
  space,
  layout,
  color,
  background,
  border,
  flexbox,
  grid,
  position,
  shadow,
  typography,
  compose,
} from 'styled-system';

export const ButtonBase = styled.button.withConfig({
  shouldForwardProp: (prop, defValidFunc) => defValidFunc(prop),
})<PropsWithChildren<ButtonProps>>(
  () => css`
    /** Reset/Base  */
    border: 0;
    transition: background-color, filter 0.3s ease;
    cursor: pointer;
    outline: none;
    box-sizing: border-box;
    white-space: nowrap;
    font-size: 14px;
    &:hover {
      filter: brightness(85%);
    }
    &:focus,
    &:active {
      filter: brightness(100%);
      outline: none;
    }
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 550px) {
      &:hover {
        filter: brightness(100%);
      }
    }
  `,

  ({ variant, theme }) =>
    variant === 'primary' &&
    css`
      /** Primary  */
      color: ${theme.colors.bg};
      background-color: ${theme.colors.primary};
    `,
  ({ outlined }) =>
    outlined &&
    css`
      background-color: transparent;
      border-width: 1px;
      border-style: solid;
    `,
  ({ kind }) =>
    (kind === 'normal' || kind === undefined) &&
    css`
      border-radius: 100px;
      padding: 8px 16px;
      height: 40px;
      min-width: 140px;
      width: max-content;
      line-height: 24px;
    `,
  ({ kind }) =>
    kind === 'square' &&
    css`
      padding: 10px;
      border-radius: 4px;
    `,
  ({ kind }) =>
    kind === 'icon' &&
    css`
      border-radius: 100px;
      padding: 0;
      width: 40px;
      height: 40px;
    `,
  ({ kind }) =>
    kind === 'modal' &&
    css`
      border-radius: 100px;
      height: 30px;
      width: max-content;
      min-width: 140px;
      font-size: 14px;
      padding: 0 14px;
    `,
  ({ kind }) =>
    kind === 'small' &&
    css`
      border-radius: 100px;
      height: 20px;
      width: max-content;
      font-size: 12px;
      padding: 0 20px;
    `,
  ({ kind }) =>
    kind === 'large' &&
    css`
      border-radius: 100px;
      height: 48px;
      width: 100%;
      font-size: 16px;
      padding: 0 20px;
      text-transform: uppercase;
      font-weight: 700;
    `,
  ({ shape }) =>
    shape === 'circle' &&
    css`
      width: 40px;
      height: 40px;
      padding: 0;
    `,
  ({ disabled, theme }) =>
    disabled &&
    css`
      cursor: not-allowed;
      bakcground-color: ${theme.colors.blueDisabled};
    `,
  ({ shadow }) => css`
    ${typeof shadow === 'boolean' &&
    shadow === true &&
    css`
      box-shadow: 0px 4px 4px rgba(93, 103, 160, 0.15),
        0px 4px 16px rgba(93, 103, 160, 0.08);
    `}
    ${typeof shadow === 'string' &&
    css`
      box-shadow: ${shadow};
    `}
  `,

  compose(
    space,
    layout,
    color,
    background,
    border,
    flexbox,
    grid,
    position,
    shadow,
    typography
  )
);
