import React from 'react';
import { Modal, Drawer, DrawerProps, ModalProps } from 'antd';
import { mobileWidth, useIsMobile } from '../../hooks/useBreakPoint';
import {
  Body,
  GlobalModalStyle,
  Success,
} from './ModalConfirm.ui';
import { ModalConfirmProps } from './types';
import useConfirmClose from './useConfirmClose';
import { px } from '../../utils';

export default function ModalConfirm(props: ModalConfirmProps) {
  const {
    children,
    FooterElement,
    raw,
    show,
    success,
    rootBoxProps,
    title,
    titleSuccess,
    drawer,
    footer,
    footerProps,
    loading,
    textSuccessOk,
    onClose,
    onSuccessOk,
    setSuccess,
    textOk = 'Yes',
    textClose = 'No',
    isFormValid,
    drawerHeight,
    customDesktopWidth,
    okProps: okPropsBase,
    closeProps: closePropsBase,
    bodyHeight,
    centerBodyElements,
    ...restProps
  } = props;

  const isDrawer = !success && drawer;
  const BaseComp = isDrawer ? Drawer : Modal;
  const isMobile = useIsMobile();

  const handleClose = useConfirmClose(props);

  const baseCompProps: DrawerProps | ModalProps = {
    placement: 'bottom' as any,
    onClose: handleClose,
    onCancel: handleClose,
  };


  const renderBody = () => (
      <Body
          full={restProps.full}
          drawer={isDrawer}
          isMobile={isMobile}
          title={title}
          rootBoxProps={rootBoxProps}
          customDesktopWidth={customDesktopWidth}
          bodyHeight={bodyHeight}
          centerBodyElements={centerBodyElements}
          footer={FooterElement ? <FooterElement/> : null}
      >
        {success ? <Success titleSuccess={titleSuccess} /> : children}
      </Body>
  );

  return (
      <>
        {show && (
            <GlobalModalStyle
                drawerHeight={drawerHeight}
                success={success}
                isMobile={isMobile}
                {...restProps}
            />
        )}

        <BaseComp
            width={isMobile ? px(mobileWidth) : 'max-content'}
            open={show}
            destroyOnClose={true}
            centered
            rootStyle={{ padding: 0 }}
            footer={null}
            closable={false}
            closeIcon={<> </>}
            {...(baseCompProps as object)}
            {...restProps}
        >
          {raw ? children : renderBody()}
        </BaseComp>
      </>
  );
}
