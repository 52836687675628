import {css} from "styled-components";
import {forwardRef, ReactNode} from "react";
import {Box, FlexProps} from "@keyslabor/shared/ui";

const maxWidth = '335px';

const interviewScreenStyles = css`
  max-width: ${maxWidth};
  align-items: flex-start;
  flex-direction: column;
  justify-content: stretch;
  overflow: hidden;
  padding-bottom: 0;
  display: grid;
  max-height: 100%;
  padding-top: 10px;
`

const contentWrapperStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
    min-height: 0;
    height: auto;
    max-height: 100%;
`

export const SlideWrapper = forwardRef<HTMLDivElement, FlexProps & {
    after?: ReactNode;
}>(({ children, after, ...props }, ref) => {
    const answerGridCss = css`
        ${interviewScreenStyles}
        ${after ? css`grid-template-rows: auto 52px` : css`grid-template-rows: auto`}
    `
    return (
        <Box className="answer-top" css={answerGridCss} ref={ref} {...props}>
            <Box css={contentWrapperStyles}>
                {children}
            </Box>
            {after}
        </Box>
    );
})
