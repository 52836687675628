import {FlexProps} from "@keyslabor/shared/ui/layout/Flex/Flex.types.ts";
import {useRef, useState} from "react";
import {Box, Flex} from "@keyslabor/shared/ui";
import {ArrowDownIcon, ArrowUpIcon} from "@/assets";

const COLLAPSED_ANSWER_LENGTH = 35;
const height = '42px';

export const CollapsibleQuestionAnswer = ({
                                              children,
                                              ...restProps
                                          }: {
    children: string;
} & FlexProps) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const ref = useRef<HTMLDivElement>(null);
    const isCollapsable = children.length > COLLAPSED_ANSWER_LENGTH;
    const shortAnswer = children.slice(0, COLLAPSED_ANSWER_LENGTH) + '...';

    return (
        <Flex ref={ref} position="relative" flexDirection="row" zIndex={100}>
            {!isCollapsed && <Box height="59px"> </Box>}
            <Flex
                boxShadow="0px 4px 4px 0px rgba(20, 57, 128, 0.1)"
                borderRadius="10px"
                p="11px 10px"
                flexDirection="row"
                backgroundColor="white"
                position={isCollapsed ? 'relative' : 'absolute'}
                minWidth="335px"
                height={isCollapsed ? height : 'max-content'}
                minHeight={height}
                maxHeight={isCollapsed ? height : '300px'}
                overflow="auto"
                zIndex={isCollapsed ? 0 : 1000}
                alignItems={'center'}
                {...restProps}
            >
                <Box
                    flexGrow={1}
                    fontSize="14px"
                    lineHeight="16px"
                    onClick={() => {
                        setIsCollapsed(!isCollapsed);
                    }}
                >
                    <strong css={`margin-right: 4px; color: #143980;`}>Answer:</strong>
                    {(isCollapsed && isCollapsable) ? shortAnswer : children}
                </Box>
                {isCollapsable && (
                    <Flex
                        width="20px"
                        minWidth="20px"
                        position="relative"
                        onClick={() => {
                            setIsCollapsed(!isCollapsed);
                        }}
                        css="
                        & > svg > path {
                          fill: #143980 !important;
                          opacity: 1 !important;
                        }
                      "
                    >
                        {isCollapsed ? <ArrowDownIcon /> : <ArrowUpIcon />}
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};
