import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import theme, { antdColorsConfig, antdTypographyConfig } from '../theme';
import {ReactNode, useEffect} from 'react';
import PullToRefresh from 'pulltorefreshjs';

export default function AppContext({
  children,
}: {
  children: ReactNode;
}) {

    useEffect(() => {
        const isPWA = window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone;

        if (isPWA) {
            PullToRefresh.init({
                mainElement: 'body',
                onRefresh() {
                    window.location.reload();
                },
            });
        }

        return () => {
            PullToRefresh.destroyAll();
        }
    }, []);

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            ...antdColorsConfig,
            ...antdTypographyConfig,
          },
        }}
      >
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </ConfigProvider>
    </>
  );
}
