import { Table as TableBase, TableProps } from 'antd';
import { css } from 'styled-components';
import theme from '../../theme';
import React from 'react';
import { px } from "../../utils.ts";
import { mobileBreakpoint } from '../../hooks/useBreakPoint.ts';

interface TableStyleProps {
  rowHeight?: string;
  headerHeight?: string;
  headerPadding?: string;
  borderRadius?: string;
  rowMargin?: string;
  rowPadding?: string;
  disableBorderInlineEnd?: boolean;
}

const borderWidth = '1.5px';
const borderColor = theme.colors.grayLight;

const nowrap = css`
  word-break: break-word;
  word-break: break-all;
`;

const styles = (tableStyles: TableStyleProps) => css`
  table {
    border: ${borderWidth} solid ${borderColor};
    border-radius: ${tableStyles?.borderRadius || '8px'};
    width: 100%;
    max-width: 1088px;

    thead.ant-table-thead {
      & > tr {
        height: ${tableStyles?.headerHeight || '36px'};
        & > th {
          background-color: transparent;
          border-bottom: ${borderWidth} solid ${borderColor};
          border-inline-end: ${tableStyles?.disableBorderInlineEnd
            ? 'none'
            : `${borderWidth} solid ${borderColor}`};
          padding: ${tableStyles?.headerPadding || '4px 10px'};
          text-align: center;
          font-weight: 500;
          font-size: 14px;
          overflow: hidden;
          text-align: center;
          height: ${tableStyles?.headerHeight || '36px'};
          &:before {
            display: none;
          }
        }

        & > th:last-of-type {
          border-inline-end: none;
        }
      }
    }

    tbody.ant-table-tbody {
      & > tr {
        & > td {
          border-bottom: ${borderWidth} solid ${borderColor};
          border-inline-end: ${borderWidth} solid ${borderColor};
          // font-weight: 500;
          font-size: 14px;
          overflow: hidden;
          text-align: center;
          padding: ${tableStyles?.rowPadding || '6px 10px'};
          // word-break: break-all;
          height: ${tableStyles?.rowHeight || 'auto'};
        }

        & > td:last-of-type {
          border-inline-end: none;
        }
      }

      & > tr:nth-child(odd) {
        background-color: #f2f3f8;
      }

      & > tr:last-of-type {
        border-bottom-right-radius: ${tableStyles?.borderRadius || '8px'};
        border-bottom-left-radius: ${tableStyles?.borderRadius || '8px'};

        & > td {
          border-bottom: none;
        }
        & > td:first-of-type {
          border-bottom-left-radius: ${tableStyles?.borderRadius || '8px'};
        }
        & > td:last-of-type {
          border-bottom-right-radius: ${tableStyles?.borderRadius || '8px'};
        }
      }
    }
  }
  
  &.plain table {
        border-radius: 0;
        border: unset;

        thead.ant-table-thead > tr > th {
            border: unset;
            height: 70px;
        }
      
        tbody.ant-table-tbody > tr > td {
            border: unset;
        }


      @media screen and (max-width: ${px(mobileBreakpoint)}) {
          & > tbody.ant-table-tbody > tr:nth-child(odd) {
              background-color: unset;
          }

          & > tbody.ant-table-tbody tr td {
              padding: 10px 0;
              border-top: 1px solid #E5E7F0;
          }
      }
      
    }
    
`;

const rowColor = css`
  table {
    tbody.ant-table-tbody {
      & > tr:nth-child(odd) {
        background-color: #ffffff !important;
      }
    }
  }
`;

const combined = (isEmpty, tableStyles) => css`
  ${styles(tableStyles)}
  ${!isEmpty && rowColor}
`;

function Table(props: TableProps<any> & { tableStyles?: TableStyleProps }) {
  const isEmpty = !!props?.dataSource?.length;
  return (
    <TableBase
      css={combined(isEmpty, props?.tableStyles)}
      {...props}
      pagination={
        props.pagination === false
          ? false
          : {
              showSizeChanger: false,
              pageSize: 16,
              ...props.pagination,
            }
      }
    />
  );
}

export default Table;
