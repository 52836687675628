import { useGetQuestions } from '@/api/services';
import { routes } from '@/constants';
import { useQuestionsAnswers } from '@/hooks/useQuestionsAnswers';
import { useNavigate } from 'react-router-dom';

export default function useGetQuestionWithAnswered() {
  const navigate = useNavigate();

  const {
    invitationKey,
    language,
    currentQuestionNumber,
    langContext,
    setCurrentQuestionNumber,
  } = useQuestionsAnswers();

  const { data, isLoading } = useGetQuestions({
    input: {
      invitation_key: invitationKey,
      language,
      question_num: currentQuestionNumber,
    },
    onError: (err) => {
      if (
        err instanceof Error &&
        err.message === '{"is_already_answered":true}'
      ) {
        setCurrentQuestionNumber(currentQuestionNumber + 1);
      } else if (
        err instanceof Error &&
        err.message === '{"error":"Questions not found"}'
      ) {
        navigate(routes.finish);
      }
    },
  });

  const questionName = data?.question?.name?.match(/\d+/)?.[0];
  const questionText = data?.question.text;
  const questionVideo = data?.question.video_url;
  const questionTopics = data?.question.topics.split(', ') || [];

  const handleNextStep = () => {
    navigate(routes.answers);
  };
console.log({langContext})
  return {
    questionName,
    questionText,
    questionVideo,
    isEng: language === 'english',
    isLoading: isLoading || !data,
    selectedLanguage: language,
    questionTopics,
    data,
    invitationKey,
    totalQuestions: langContext.questionsTotal || 0,
    questionNumber: currentQuestionNumber,
    handleNextStep,
    onNextQuestion: setCurrentQuestionNumber,
  };
}
