import React from 'react';
import {Logo, DollarIcon } from "@/assets";
import LayoutPageMobile from "@/components/LayoutPageMobile";
import {Flex, Row, Text} from "@keyslabor/shared/ui";
import {useInterviewOfferPreview, useInterviewOfferConfirmed} from "@/api/services.ts";
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Button from "@keyslabor/shared/ui/Button";
import {LocationIcon, CalendarIcon, KeyIcon, SuccessIcon} from '@/assets/offer'
import OfferConfirmedScreen from "./OfferConfirmedScreen.tsx";

const OfferConfirmation = () => {
    const {t} = useTranslation('confirmation')
    const [offerConfirmed, setOfferConfirmed] = React.useState(false);
    const [searchParams] = useSearchParams()
    const invitationKey = searchParams.get('invitation_key')
    const {data, error, isLoading} = useInterviewOfferPreview(invitationKey);
    const {mutateAsync: triggerInterviewOfferConfirmed} = useInterviewOfferConfirmed();

    const handleOrderConfirmed = async () => {
        await triggerInterviewOfferConfirmed({invitation_key: invitationKey});
        setOfferConfirmed(true);
    }

    const isOfferConfirmed = data?.interview_status === 'CONFIRM_BY_EMPLOYEE' || offerConfirmed;

    if (error) {
        return <OfferConfirmedScreen/>
    }

    const formatedDate = data?.first_working_day ? new Date(data?.first_working_day).toLocaleString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    }) : 'N/A'

    return (
        <LayoutPageMobile
            loading={isLoading}
            footer={isOfferConfirmed ? (
                <Row
                    py="20px"
                    borderTopLeftRadius="4px"
                    borderTopRightRadius="4px"
                    width="100%"
                    flexCenter
                >
                    <Text fontWeight="600" textAlign="center" mb="30px" px="12px" fontSize="20px">
                        {t('offerConfirmedMessage')}
                    </Text>
                    <SuccessIcon/>
                </Row>
            ) : (
                <Row
                    py="20px"
                    borderTopLeftRadius="4px"
                    borderTopRightRadius="4px"
                    width="100%"
                    flexCenter
                >
                    <Text fontWeight="600" textAlign="center" mb="30px" px="12px" fontSize="20px" color={'#E01212'}>
                        {t('confirmMessage')}
                    </Text>
                    <Button
                        overflow="hidden"
                        position="relative"
                        maxWidth="332px"
                        width="100%"
                        kind="large"
                        onClick={handleOrderConfirmed}
                    >
                        {t('confirmLabel')}
                    </Button>
                </Row>
            )}
        >
            <Row flexCenter maxWidth="480px" justifySelf="center">
                <Logo/>
                <Row flexCenter mt="70px">
                    <Text fontWeight="900" mt="" fontSize="20px" upper textAlign="center">
                        {t('hello')} <br/>
                        {data?.name}
                    </Text>
                </Row>
                <Row mt="70px" justifySelf="flex-start" px="22px" >
                    {Number(data?.pay_rate) ? (
                        <Flex mb={"20px"} alignItems="center">
                            <DollarIcon css={`width: 18px; height: 18px;`}/>
                            <Text ml="12px" fontWeight="700" fontSize="16px" color="black" mr="4px">
                                {t('pay_rate')}
                            </Text>
                            <Text>${data?.pay_rate}</Text>
                        </Flex>
                    ) : null}
                    <Flex mb={"20px"} alignItems="center">
                        <CalendarIcon/>
                        <Text ml="12px" fontWeight="700" fontSize="16px" color="black" mr="4px">
                            {t('firstWorkDay')}
                        </Text>
                        <Text>
                            {formatedDate}
                        </Text>
                    </Flex>
                    <Flex mb={"20px"} alignItems="center">
                        <KeyIcon/>
                        <Text ml="12px" fontWeight="700" fontSize="16px" color="black" mr="4px">
                            {t('position')}
                        </Text>
                        <Text>{data?.position}</Text>
                    </Flex>
                    <Flex alignItems="center">
                        <LocationIcon/>
                        <Text ml="12px" fontWeight="700" fontSize="16px" color="black" mr="4px">
                            {t('location')}
                        </Text>
                        <Text>{data?.property_address}</Text>
                    </Flex>
                </Row>
                <Row px="22px" mt="40px">
                    <Text fontWeight="500" fontSize="14px" color="black">
                        {t('notification')}
                    </Text>
                </Row>
            </Row>
        </LayoutPageMobile>
    );
};

export default OfferConfirmation;
